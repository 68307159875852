<template>
    <div>
        <el-dialog id="reorganize" :title="title" width="70%" :close-on-click-modal="false" :visible.sync="visible">
            <div class="m_b1" v-if="dataListAllSelections.length > 0">
                <div class="m_b1">已关联文件：</div>
                <el-tag size="small"
                        :key="tag.id"
                        v-for="tag in dataListAllSelections"
                        closable
                        :disable-transitions="false"
                        @close="handleClose(tag)">
                    {{tag.fileName}}
                </el-tag>
            </div>
            <div class="lump p_all1">
                <el-col :span="12" class="flex_l_c m_b1">
                    <el-input class="m_r1" v-model="srhStr" maxlength="66" placeholder="请输入文件名称" size="small" clearable></el-input>
                    <el-button type="primary" @click="search()" size="small" icon="el-icon-search">查询</el-button>
                    <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
                </el-col>
                <el-table
                        ref="multipleTable"
                        :data="dataList"
                        size="small"
                        v-loading = "loading"
                        height="calc(100vh - 340px)"
                        class="table" @selection-change="selectionChangeHandle">
                    <el-table-column type="selection" width="50" />
                    <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip />
                    <el-table-column prop="updateName" label="更新人"  show-overflow-tooltip />
                    <el-table-column prop="updateDate" label="更新时间" show-overflow-tooltip />
                    <el-table-column fixed="right" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" plain @click="view(scope.row.id)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">取消</el-button>
                <el-button size="small" type="primary" @click="affirm()" v-noMoreClick>确认</el-button>
        </span>
        </el-dialog>
        <!-- 查看 -->
        <FileDetails ref="fileDetails" @refreshDataList="refreshList"></FileDetails>
    </div>

</template>

<script>
    import FileDetails from '../documents/fileDetails'
    export default {
        components: {
            FileDetails
        },
        data() {
            return {
                title: '关联',
                visible: false,
                srhStr: '',
                dataList: [],
                loading: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataListAllSelections: [],   // 所有选中的数据包含跨页数据
                dataListSelections: [],
                idKey: 'id', // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
                editForm: {
                    ids:"",
                    quanzongNo:"",
                    uploadTarget:"",
                    assTypeValue:1,
                    type:1
                },
                log:{
                    operModular:"1",//模块
                    operType:"6",//类型：关联
                    operTerm:"",//内容
                    operSystem:"1",//结果
                },
            }
        },
        methods: {
            init(row,exhibitionLabel,exhibitionVal) {
                this.dataListAllSelections = []
                this.title = '关联（正在关联'+exhibitionLabel.exhibitionName+'目录：'+row.directoryName + ')'
                this.editForm.quanzongNo=exhibitionLabel.id
                this.editForm.uploadTarget=row.id
                this.srhStr=""
                this.visible = true;
                this.refreshList()
            },
            search(){
                this.pageNo = 1
                this.refreshList();
            },
            // 查询
            refreshList() {
                this.loading = true
                this.$axios(this.api.zlzs.queryAllByLimitAssociated, {
                    'name':this.srhStr,
                    "quanzongNo":this.editForm.quanzongNo,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                    this.$nextTick(() => {
                        this.setSelectRow()
                    })
                })
            },
            view(id){
                this.$refs.fileDetails.init(id, 'recycle')
            },
            // 多选
            selectionChangeHandle(val){
                this.dataListSelections = val
                this.$nextTick(() => {
                    this.changePageCoreRecordData()
                })
            },
            // 设置选中的方法
            setSelectRow() {
                if (!this.dataListAllSelections || this.dataListAllSelections.length <= 0) {
                    this.$refs.multipleTable.clearSelection()
                    return
                }
                // 标识当前行的唯一键的名称
                let idKey = this.idKey
                let selectAllIds = []
                this.dataListAllSelections.forEach(row => {
                    selectAllIds.push(row[idKey])
                })
                this.$refs.multipleTable.clearSelection()
                for (var i = 0; i < this.dataList.length; i++) {
                    if (selectAllIds.indexOf(this.dataList[i][idKey]) >= 0) {
                        // 设置选中，记住table组件需要使用ref="table"
                        this.$refs.multipleTable.toggleRowSelection(this.dataList[i], true)
                    }
                }
            },
            // 记忆选择核心方法
            changePageCoreRecordData() {
                // 标识当前行的唯一键的名称
                let idKey = this.idKey
                let that = this
                // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
                if (this.dataListAllSelections.length <= 0) {
                    this.dataListSelections.forEach(row => {
                        that.dataListAllSelections.push(row)
                    })
                    return
                }
                // 总选择里面的key集合
                let selectAllIds = []
                this.dataListAllSelections.forEach(row => {
                    selectAllIds.push(row[idKey])
                })
                let selectIds = []
                // 获取当前页选中的id
                this.dataListSelections.forEach(row => {
                    selectIds.push(row[idKey])
                    // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
                    if (selectAllIds.indexOf(row[idKey]) < 0) {
                        that.dataListAllSelections.push(row)
                    }
                })
                let noSelectIds = []
                // 得到当前页没有选中的id
                this.dataList.forEach(row => {
                    if (selectIds.indexOf(row[idKey]) < 0) {
                        noSelectIds.push(row[idKey])
                    }
                })
                noSelectIds.forEach(id => {
                    if (selectAllIds.indexOf(id) >= 0) {
                        for (let i = 0; i < that.dataListAllSelections.length; i++) {
                            if (that.dataListAllSelections[i][idKey] === id) {
                                // 如果总选择中有未被选中的，那么就删除这条
                                that.dataListAllSelections.splice(i, 1)
                                break
                            }
                        }
                    }
                })
            },
            // 删除已关联文件
            handleClose(tag) {
                this.dataListAllSelections.splice(this.dataListAllSelections.indexOf(tag), 1)
                this.$nextTick(() => {
                    this.setSelectRow()
                })
            },
            // 重置
            resetSearch() {
                this.srhStr = "";
                this.search()
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.refreshList();
                this.$nextTick(() => {
                    this.changePageCoreRecordData()
                })
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.refreshList();
                this.$nextTick(() => {
                    this.changePageCoreRecordData()
                })
            },
            // 确认
            affirm() {
                if(this.dataListSelections.length == 0) {
                    this.$message('请选择关联文件')
                    return
                }
                // 批量修改多个id用逗号隔开
                this.editForm.ids=this.dataListAllSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定关联所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.zlzs.fileCollectBatchUpdateById, this.editForm, 'put').then(data => {
                        if(data.status){
                            this.$message.success("关联成功")
                            this.log.operSystem = '0'
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }else{
                            this.$message.error(data.msg)
                            this.log.operSystem = '1'
                        }
                        // this.logSave()
                    })
                })

            },
            //新增日志
            logSave(){
                let that = this;
                let batchList = []
                this.dataListAllSelections.forEach(function (v) {
                    // console.log(v.fileName);
                    let log = JSON.parse(JSON.stringify(that.log))
                    log.operTerm = v.fileName
                    console.log(log.operTerm);
                    batchList.push(log)
                })
                this.$axios(this.api.zlzs.batchInsertLogin,batchList,'post').then(data => {
                    // console.log("日志：",data);
                    // if(data.status){
                    // this.getFileList()
                    // }
                })
            },
        }
    }
</script>

<style scoped>

</style>
